/**
 * SendableFilter Command
 * @desc Filters the list of sendables by using the configuration of filters 
 * @author: Dave Ashenden 
 */
const SendableFilter = (sendables, term) => {
    let success = false
    let result = {}

    
    
    const checkField = (field, term) => {
        if(field != null)
            return field.indexOf(term) > -1
        return false
    }

    // let
    Object.keys(sendables).filter(
        (key) => {
            const sendable = sendables[key]
            const inName = checkField(sendable.name, term)
            const inDesc = checkField(sendable.description, term)
            const inTags = checkField(sendable.tag_list.join(''), term)
            
            return inName || inDesc || inTags
        }
    )
    // Orignal sender should do this if needded for permormance
    .map(
        (key,idx) => {
            result[key] = sendables[key]
            return null 
        }
    )
    
    success = result.length > 0// console.log("RESULT", result)
    

    return [success, result]
}

export default SendableFilter


