import React, { useEffect, useState, useContext } from "react";
import styles from "./InvitationSummaryComponent.module.sass";
import cx from "classnames";

import PropTypes from "prop-types";
// import axios from "axios";

// import { AuthContext } from "../../context/AuthContext";
// import { AppContext } from "../../context/AppContext";
// import { OrderContext } from "../../context/OrderContext";

// import InvitationFormItem from "../InvitationFormItem";
// import LoadingContainer from "../LoadingContainer";
// import InvitationFormMessageWidget from "../InvitationFormMessageWidget";
// import InvitationSummaryItem from '../InvitationSummaryItem';
import { skuKey } from "../../commands/JsonSchemaTools/CreateVariantField";

/*
 * InvitationSummary Component
 */
const InvitationSummary = props => {
  const { invitation } = props;
  
  // our factory
  return (
    <div className={cx("Invitationformfactory-component", styles.container)}>
      <div className={cx("card my-3 ")}>
        <div className="card-body ">
          <div className="d-flex flex-row h-100">
            <div className="d-flex flex-column">
              <h5>Message</h5>
              <p>{invitation.message}</p>
              <h5>Expires in</h5>
              <p>{invitation.expires_in_days} days</p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <button
          className="btn btn-secondary btn-block mt-3"
          onClick={props.onEdit}
        >
          Change
        </button>
      </div>
    </div>
  );
};

InvitationSummary.propTypes = {
  invitation: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired
};

InvitationSummary.defaultProps = {
  onEdit: () => {}
};

export default InvitationSummary;
