
import React, { useEffect, useState, useContext } from 'react'
import styles from './NotesFormComponent.module.sass'
import cx from 'classnames'


import {
    message,
    Form,
    Input,
    Button,
    Divider
} from "antd";
import { AppContext } from '../../context/AppContext'
import { OrderContext } from "../../context/OrderContext";



const { TextArea } = Input;
/*
 * @name NotesForm Component 
 * @description Form for the user to add any additional notes to the fulfiller 
 * @author Dave Ashenden <hello@thesubversiveagency.com>
 */
const NotesForm = (props) => {
    const { activeSendable } = useContext(AppContext)
    const {
        notes,
        setNotes
    } = useContext(OrderContext);


    const handleChange = val => {
        // console.log("notes chenged", val.currentTarget, val.target.value)
        setNotes(val.target.value)
    };

    // -------------------
    // Render
    // -------------------
    const { getFieldDecorator } = props.form;

    return (
        <div className={cx("container text-left", styles.container)}>
            <div className="row no-gutters">

                <Form
                    className="w-100"
                // {...formItemLayout}
                // onSubmit={handleSubmit}
                // layout="horizontal"
                >

                    <div className="row">

                        <div className="col-12">
                            <Form.Item hasFeedback>
                                {getFieldDecorator("notes", {
                                    initialValue: notes,
                                })(<TextArea rows={4} placeholder="eg: Please add an extra bundle of sweets..."
                                    onChange={handleChange} className="form-control form-control-lg border-0" />)}
                            </Form.Item>
                        </div>
                    </div>

                </Form>
            </div>
        </div>
    );
}

export default Form.create({})(NotesForm)


