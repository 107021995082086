
import React, {useContext} from 'react'
import { AppContext } from '../../context/AppContext';
import { Spin } from 'antd';

/*
 * LoadingContainer Component 
 */
const LoadingContainer = (props) => {

    const {isLoading} = useContext(AppContext)
    return <Spin spinning={isLoading}>
        {props.children}
    </Spin>
}

export default LoadingContainer


