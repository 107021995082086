import React from 'react'
import cx from 'classnames'
import styles from './SendableCard.module.sass'
import PropTypes from 'prop-types'
import { Card, Button, Tag } from "antd";


const { Meta } = Card;
const SendableCard = (props) => {

  const { sendable } = props
  return (
    <React.Fragment>
      <Card
        onClick={props.onClick}
        {...props}
        className="m-1"
        hoverable={props.hoverable}

      >

        <Meta
          avatar={
            <img
              style={{ height: 100 }}
              alt={sendable.name + " image"}
              src={sendable.image_full || sendable.items[0].image_full}
            />

          }
          title={sendable.name}
          description={
            <span>
              <div className={cx("card-tag-list", styles.tags)}>
                {sendable.tag_list.map((tag, idx) => {
                  return (
                    <Tag
                      className="text-black"
                      // color="#686a68"
                      size="small"
                      key={sendable.id + "-tag-" + tag}
                    >
                      {tag}
                    </Tag>
                  );
                })}
              </div>
              <Button className="mt-2" type="primary">Send this</Button>
            </span>
          }
        />

      </Card>

    </React.Fragment>
  );

}


SendableCard.propTypes = {
  sendable: PropTypes.object.isRequired,
  // isSelected: PropTypes.bool
}
SendableCard.defaultProps = {
  hoverable: true,
  // isSelected: false,
  onClick: () => null
}

export default SendableCard