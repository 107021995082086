import React from 'react'
import PropTypes from 'prop-types'

import NavBar from './NavBar'

import '../sass/index.sass'
import Covid19banner from './Covid19banner';


const Layout = props => (
  <React.Fragment>
    {/* <Covid19banner/> */}
    {process.env.NODE_ENV !== "production" && (
      <div className="notprod-banner">
        The current ENV is {process.env.NODE_ENV}
      </div>
    )}
    <NavBar />
    {props.children}
  </React.Fragment>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
