import React, {useContext, useEffect} from 'react'
import styles from './styles.module.sass'
import cx from 'classnames'
import CustomerEditor from '../../components/CustomerEditor'
import SendableReview from '../../components/SendableReview'
import StepTabs from '../../components/StepTabs'
import {AppContext} from '../../context/AppContext'
import {OrderContext} from '../../context/OrderContext'
// import { createBrowserHistory } from 'history'
import {withRouter} from 'react-router'
const CustomerPage = (props) => {

    const {activeSendable} = useContext(AppContext)
    const {
      updateCustomer,
      incrementState,
      isStateComplete
      //     setCustomerSubmitted
    } = useContext(OrderContext);

    useEffect( () => {
       
        // if(!activeSendable) {
        //     // setActiveStep('select')
        //     props.history.push('select')
        // }
        if(!isStateComplete('sendable')) {
            props.history.push('/select')
            return
        }
        
    }, [])
    
    const handleOnSubmit = (data) => {

        // console.log("SETTING DATA FOR CUSTOMER", data)
        updateCustomer(data)
        incrementState('customer')
        // setCustomerSubmitted(true)
        // setActiveStep÷('customise')

        // TODO here we need to decide if we should push the apps configuration page, for now, always show it
        // props.history.push('customise')
        props.history.push('apps')

    }

    return (
    
    <div className="page-wrapper">
        <StepTabs/>
        <div className={cx('container-fluid d-flex align-items-stretch align-content-stretch flex-column bd-highlight mb-3', styles.container)}>
            
            <div className={cx('mb-auto row', styles.footer)}>
                <CustomerEditor onSubmit={(e) => handleOnSubmit(e)}/>
                <div className="container mt-5">
                    <div className="row">
                        <div className="col">
                            <h3 className="title mt-3 mb-3">Selected Package</h3>
                            {activeSendable && <SendableReview sendable={activeSendable} className="half-height"/>}
                        </div>
                    </div>
                </div>
                
            </div>

        </div>
        
    </div>

        )
    }

export default withRouter(CustomerPage)

