
import React, { useState, useEffect } from 'react'
import styles from './PersonalisationFormMessageWidgetComponent.module.sass'
import cx from 'classnames'
import PropTypes from 'prop-types'
import MessageRender from '../../commands/MessageRender/MessageRender';
/*
 * PersonalisationFormMessageWidget Component 
 */
const PersonalisationFormMessageWidget = (props) => {
    const charLimit = 1000

    const { substitutions } = props.options
    // const substitutions = {pie:"waffle", firstname: "Jjay", lastname: "mccoolface"}
    // const [tags, setTags] = useState([])
    const [editor, setEditor] = useState(null)
    const [cursorPos, setCursorPos] = useState(0)
    const [charCount, setCharCount] = useState(0)
    const { value, onChange } = props
    // const [active,setActive] = useState(false)

    useEffect(
        () => {
            if (editor) {
                const newMessage = MessageRender(value, substitutions)
                // console.log('PersonalisationFormMessageWidget --> init effect' , newMessage, value)
                // console.log(substitutions)

                setCursorPos(editor.selectionEnd)
                setCharCount(newMessage.length)
                // setMessage(newMessage)
                onChange(newMessage)
            }
        }, [editor]
    )
    useEffect(
        () => {
            if (editor) {
                // console.log("")
                editor.selectionEnd = cursorPos
            }
        },
    )

    const handleChange = (e) => {
        if (editor) {
            // console.log("changed", e.target.value)
            // const previewMessage =  MessageRender(message, customer)
            setCharCount(e.target.value.length)
            setCursorPos(editor.selectionEnd)
            const newMsg = MessageRender(e.target.value.substring(0, e.target.value.length), substitutions)
            // console.log("compare", e.target.value.localeCompare(newMsg))

            onChange(newMsg)
            if (e.target.value.localeCompare(newMsg) !== 0) {
                // console.log('udpated', editor.selectionEnd + (newMsg.length - e.target.value.length +2))
                setCursorPos(cursorPos + (newMsg.length - e.target.value.length +1))
            }
        }


    }


    const handleQuickTag = (key) => {
        const { selectionStart, selectionEnd } = editor
        console.log("handleQuickTag", selectionStart, selectionEnd)
        setCursorPos(editor.selectionEnd)
        const orginalPos = selectionStart
        const newMsgTmp = value.substring(0, selectionStart) + '!' + key + value.substring(selectionEnd)
        const newMsg = MessageRender(newMsgTmp, substitutions)
        onChange(newMsg)
        // editor.selectionStart = selectionStart
        // editor.focus()
        setCursorPos(orginalPos + (newMsg.length - newMsgTmp +1))

    }

    const handleKeyPress = e => {
        let keyPressed = e.key;

        if (/[0-9]/.test(keyPressed)) {
            e.preventDefault();
        }
    }
    return (
        <div className={cx("container-fluid text-left", styles.container)}>
            <div className="row no-gutters">
                <div className="col-12">


                    {/* <h3 className="title mb-4 mt-4">Edit your value:</h3> */}

                    <div className="form text-left">

                        <div className="form-group mb-5 mt-0 border-top-0">
                            {/* <label htmlFor="" className="col-form-label-lg">Edit your value
                            <small id="passwordHelpBlock" class="form-text text-muted">Edit your value</small>
                        </label> */}

                            <textarea rows={10}
                                value={value}
                                ref={(e) => { setEditor(e) }}
                                onChange={handleChange}
                                onKeyPress={handleKeyPress}
                                className={cx("form-control form-control-lg border-0 ", styles.textArea)}
                                placeholder="Edit your message here" />

                        </div>
                        <div className="text-left">{charCount} /{charLimit}</div>
                        <h6 className=" mb-4 mt-4">Smart tags <small>Click to add</small></h6>
                        <div>
                            {Object.keys(substitutions).map(
                                (key, idx) => {
                                    return <div key={'quick-button-' + key} className={cx("btn btn-dark p-2 m-1 text-white", styles.quickButton)} onClick={(e) => handleQuickTag(key)}>!{key}</div>

                                }
                            )}
                        </div>

                    </div>


                </div>

            </div>


        </div>

    )
}


PersonalisationFormMessageWidget.propTypes = {
    value: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
}

PersonalisationFormMessageWidget.defaultProps = {
    value: "",
    required: false,
    onChange: (e) => console.log(e.target.value)
}

export default PersonalisationFormMessageWidget


