
import React, { useContext } from 'react'
import styles from './CompletePageStyles.module.sass'
import highFiveImage from '../../images/highfive.gif'
import { OrderContext } from '../../context/OrderContext';

import { message } from 'antd'

import { sendPostMessageToParent } from '@matter/code-lib/postmessage/utils'
import { WIDGET_CLOSE } from '@matter/code-lib/postmessage/types'
// import LoadingContainer from '../../components/LoadingContainer';

/**
 * Complete Page
 * The page shown when a gift has been sent
 * @author Dave Ashenden <dave@thesubversivesagency.com>
 */
const CompletePage = () => {

    const { customer, sendable } = useContext(OrderContext)
    // const [loading, setLoading] = useState(true)

    // useEffect(() => {
    //     // setTimeout(() => {
    //         purge()
    //         sendPostMessageToParent(WIDGET_CLOSE, {})
    //     // },10000);
    // }, [])

    // useEffect(() => {
    //     setTimeout(() => {
    //         setLoading(false)
    //     },2000);
    // }, [])


    const onByeBye = () => {

        message.info("Bye Bye!");
        setTimeout(() => {
            // purge()
            sendPostMessageToParent(WIDGET_CLOSE, {})
        }, 3000);
    }

    const onRestart = () => window.location.reload(false);
    // const context = useContext(contextValue)
    return (
        <div className="page-wrapper">
            {/* <LoadingContainer loading={loading}> */}
            <div className="container px-2">
                <h2 className="title color-primary text-center ph-2">
                    Awesome! Your order has been received
            </h2>
                <div className="d-flex flex-column h-100">
                    <div className="d-flex flex-column flex-grow text-center">


                        <p>An order "{sendable.name}" for {customer.firstname} {customer.lastname} is now with our team. Check you email and account dashboard for updates</p>
                        <span><button className="btn btn-lg btn-link" onClick={onRestart}>Send Another</button> | <button className="btn btn-lg btn-link" onClick={onByeBye}> See you next time</button></span>
                    </div>
                    <div className="d-flex flex-column flex-grow-1">

                    </div>
                </div>
            </div>

            <div className={styles.highfive} onClick={onByeBye}>
                <img src={highFiveImage} alt="we want to high five you" />
            </div>
            {/* </LoadingContainer> */}
        </div>

    )
}

export default CompletePage


