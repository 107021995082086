import React, {useContext, useEffect} from 'react'
// import styles from './styles.module.sass'
import cx from 'classnames'


import { AuthContext } from "../../context/AuthContext";
import { AppContext } from "../../context/AppContext";
import { OrderContext } from "../../context/OrderContext";

// import MessageEditor from '../../components/MessageEditor'
import StepTabs from '../../components/StepTabs';
import PersonalisationFormFactory from '../../components/PersonalisationFormFactory';

const PersonalisePage = (props) => {
    
    const {activeSendable} = useContext(AppContext)
    const orderContext = useContext(OrderContext)

    const { currentUser } = useContext(AuthContext);
    // const { activeSendable } = useContext(AppContext);
    const  {customer, setSendable, lineItems }= useContext(OrderContext);
    // const [errors, setErrors] = useState(false)
    
    // put together the shared configuration
    const substitutions = {...customer, user_firstname: currentUser.first_name, user_email: currentUser.email, user_lastname: currentUser.last_name}
    const formConfiguration = {substitutions}

    const hasErrors = (val) =>{
    //   setErrors(val)
    }

    useEffect(() => {
        console.log( 'setSendable' , activeSendable)
        setSendable(activeSendable);
      }, [activeSendable])
    
    useEffect( () => {
       
        if(!orderContext.isStateComplete('sendable')) {
            props.history.push('/select')
        }
        
        if(!orderContext.isStateComplete('customer')) {
            props.history.push('/customer')
        }
        
    },[])

    
    const handleOnSubmit = (data) => {
        // setActiveStep('review')
        orderContext.incrementState('customise')
        props.history.push('review')

        // TODO: Should reintegrate the variants....?
        

    }
    // console.log("LINEITEMS",lineItems)
    return (
            <div className="page-wrapper">
                <StepTabs/>
                <div className={cx('container-fluid d-flex align-items-stretch align-content-stretch flex-column bd-highlight mb-3')}>
                    
                    {/* <div className={cx('mb-auto row')}>
                        <div className="col">
                            <MessageEditor/>
                        </div>
                    </div> */}
                    <PersonalisationFormFactory  
                        sendable={activeSendable} 
                        formConfiguration={formConfiguration} 
                        data={lineItems} 
                        hasErrors={hasErrors}
                        />

                    <div className={cx('mb-auto row mt-5')}>
                        <div className="col">
                            <button type="submit" className="btn btn-primary btn-icon-right btn-lg btn-block" onClick={handleOnSubmit}>
                                Review &nbsp;
                                <i className="fa fa-arrow-right"> </i>
                            </button>
                        </div>
                    </div>

                </div>
            </div>

        )
    }
export default PersonalisePage

