import React, { useEffect, useState, useContext } from "react";
import styles from "./InvitationEditorComponent.module.sass";
import cx from "classnames";

import {
  message,
  Form,
  Input,
  Button,
  Radio,
  Divider,
  Card,
  InputNumber
} from "antd";
import { AppContext } from "../../context/AppContext";
import { OrderContext } from "../../context/OrderContext";
import { AuthContext } from "../../context/AuthContext";
import MessageRender from "../../commands/MessageRender";

/*
 * @name InvitationEditor Component
 * @description allows the editing of an invitation within the ui
 * @author Dave Ashenden <hello@thesubversiveagency.com>
 */
const InvitationEditor = props => {
  // explode the props
  // const {} = props

  // -------------------
  // Constants
  // -------------------
  const { currentUser } = useContext(AuthContext);
  const [editor, setEditor] = useState(null)
  const [cursorPos, setCursorPos] = useState(0)
  const { activeSendable } = useContext(AppContext);
  const { customer, invitation, setInvitation } = useContext(OrderContext);
  const substitutions = {
    ...customer,
    sendable: activeSendable.name,
    user_firstname: currentUser.first_name,
    user_email: currentUser.email,
    user_lastname: currentUser.last_name
  };

  // -------------------
  // Effects
  // -------------------

  // useEffect(() => {
  //   console.log("invitation updated", invitation);
  // }, [invitation]);
  useEffect(() => {

    const newMessage = MessageRender(invitation.message, substitutions);
    const newInvitation = {
      ...invitation,
      message: newMessage
    };
    // props.form.setFieldsValue(newMessage);
    setInvitation(newInvitation);
  }, []);

  // -------------------
  // Handlers
  // -------------------



  const handleUpdate = e => {
    console.log('handleUpdate');
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        //   TODO SHIPIT TO THE ORDER
        const newInvitation = {
          ...invitation,
          ...values
        };
        // props.form.setFieldsValue(newMessage);
        setInvitation(newInvitation);
      } else {
        message.error(
          "Please provide the remaining details before submitting"
        );
      }
    });
  }

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        //   TODO SHIPIT TO THE ORDER
      } else {
        message.error(
          "Please provide the remaining  details before submitting"
        );
      }
    });
  };

  const getValueFromMessageEvent = (e) => {
    // console.log("getMessageValueEvent", e.target.value);
    if (!e || !e.target) {
      return e;
    }
    const { target } = e;
    setCursorPos(e.target.selectionEnd)
    const newMessage = MessageRender(target.value, substitutions);

    // console.log("localeCompare", e.target.selectionEnd, e.target.value.localeCompare(newMessage), newMessage, target.value )
    if (e.target.value.localeCompare(newMessage) !== 0) {
      // console.log("UPDATING CURSOR", cursorPos + (newMessage.length - e.target.value.length + 1),cursorPos,newMessage.length,e.target.value.length)
      setCursorPos(e.target.selectionEnd + (newMessage.length - e.target.value.length + 1))
    }
    // const newInvitation = {
    //   ...invitation,
    //   message: newMessage
    // };
    // // props.form.setFieldsValue(newMessage);
    // setInvitation(newInvitation);
    return MessageRender(target.value, substitutions);
  }

  useEffect(
    () => {
      if (editor) {
        // console.log("useEffect", cursorPos, editor.selectionEnd, editor.selectionStart)
        editor.selectionEnd = cursorPos
      }
    },
  )



  // -------------------
  // SubComponents
  // -------------------

  // -------------------
  // Render
  // -------------------
  const { getFieldDecorator } = props.form;
  return (
    <div className={cx("invitationeditor-component", styles.container)}>
      <Card title="Your invitation">
        <Form
          // {...formItemLayout}
          onSubmit={handleSubmit}
          onChange={handleUpdate}
        // layout="horizontal"
        >
          {/* <div className="row">
            <div className="col">
              <Form.Item hasFeedback label="Deliver Via">
                {getFieldDecorator("delivery_method", {
                  initialValue: invitation.delivery_method
                })(
                  <Radio.Group buttonStyle="ghost">
                    <Radio.Button value="email">Email</Radio.Button>
                    <Radio.Button value="phone">SMS</Radio.Button>
                  </Radio.Group>
                )}
              </Form.Item>
            </div>
          </div> */}

          <div className="row">
            <div className="col">
              <Form.Item
                hasFeedback
                label="Your message to the recipient"
                extra="Your details will be automatically included in the email"
              >
                {getFieldDecorator("message", {
                  getValueFromEvent: getValueFromMessageEvent,
                  initialValue: MessageRender(
                    invitation.message,
                    substitutions
                  ),
                  ref: (e) => { setEditor(e) },
                  rules: [
                    {
                      required: true,
                      message: "Please enter a salutation",
                      whitespace: false
                    }
                  ]
                })(<Input.TextArea rows={6} size="default"  />)}
              </Form.Item>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Form.Item hasFeedback label="Invite Expires in">
                {getFieldDecorator("expires_in_days", {
                  initialValue: invitation.expires_in_days
                })(
                  <Radio.Group buttonStyle="ghost">
                    <Radio.Button value={1}>A Day</Radio.Button>
                    <Radio.Button value={7}>A Week</Radio.Button>
                    <Radio.Button value={14}>2 Weeks</Radio.Button>
                    <Radio.Button value={30}>A Month</Radio.Button>
                    <Radio.Button value={90}>3 Months</Radio.Button>
                    <Radio.Button value={365}>A Year</Radio.Button>
                  </Radio.Group>
                )}
              </Form.Item>
            </div>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default Form.create({})(InvitationEditor);
