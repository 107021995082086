/**
 * LocalStorage Command
 * @desc Provides utility access to localstorage getting and setting 
 * @author: Dave Ashenden 
 */
import {useState, useEffect} from 'react';
import SecureLS from 'secure-ls'
export const storagePrefix = 'matterOperatorUI_';


const ls = new SecureLS()
/**
 * Retrieves from local storage 
 * @param {*} storageName the key to be retrieved
 */
export const loadLocal = (storageName, secure = false) => {
  try {
    const serializedState = secure ?  ls.get(storagePrefix +storageName) : localStorage.getItem(storagePrefix +storageName);
    
    
    
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
    
  } catch(err) {
    return undefined;
  }
};

/**
 * Sets local storage
 * @param {String} storageName Key for the storage
 * @param {*} state The ojbect to be stored
 */
export const saveLocal = (storageName,state, secure = false) => {
  try {
    const serializedState = JSON.stringify(state);
    if(secure) {
      // localStorage.setItem(storagePrefix+storageName, serializedState);
      ls.set(storagePrefix+storageName, serializedState)
    }else{
      
      localStorage.setItem(storagePrefix+storageName, serializedState);
    }
  } catch(err) {
    throw err;
  }
}

/**
 * 
 * @param {string} key storage key, best to match the state var
 * @param {*} initialState the initial state to be set if no local found
 */
export const useLocalState = (key, initialState = null, secure = false) => {
    const [state, setstate] = useState(initialState)
    const localData = loadLocal(key)
    
    const setLocalState = (value) => {
        setstate(value)
        saveLocal(key,value, secure)
        // console.log('setLocalState',key, value)
    }

    useEffect(
        () => { 
            // console.log(state, "VAL CHANGE")
            if(localData){
                // console.log("Found local key for " + key)
                // console.log(localData)
                setstate(localData)
            }else{
                saveLocal(key, initialState,secure)
            }
        },
        []

    )
    

    return [state, setLocalState] 
}



export default {loadLocal,saveLocal}


