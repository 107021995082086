import React, { useContext, useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
// import cx from 'classnames'
// import styles from './SendableList.module.sass'
// import PropTypes from 'prop-types'
import { Result } from 'antd'
import SendableCard from '../SendableCard';
import { AppContext } from '../../context/AppContext'
import { OrderContext } from '../../context/OrderContext'

import SendableFilter from '../../commands/SendableFilter'

const SendableList = (props) => {

  const appContext = useContext(AppContext)


  const { sendables, setActiveSendableId, sendableFilter } = appContext
  const [filtered, setFilteredState] = useState([])
  const { incrementState, setSendable } = useContext(OrderContext)

  const onCardClick = (sendableId) => {
    setActiveSendableId(sendableId)
    setSendable(sendables[sendableId])
    incrementState('sendable')
    props.history.push('/customer')
  }


  useEffect(
    () => {
      if (sendables) {
        if (sendableFilter.length > 2) {
          const r = SendableFilter(sendables, sendableFilter)[1];
          // console.log('filter keys', r) 
          setFilteredState(r);
        } else {
          const r = sendables;
          // console.log('sendable keys', r) 
          setFilteredState(r)
        }
      } else {
        setFilteredState([])
      }
      // console.log('USEEFFECT', filtered) 
    }, [sendables, sendableFilter]

  )


  return (
    <div className="container">
      {Object.keys(filtered).length < 1 && <div className="row">
        <span className="text-center"><Result
          title="Nothing available to send"

        /></span>
      </div>}
      <div className="row">
        {Object.keys(filtered).map((key, index) => {
          const sendable = sendables[key]
          return (
            <React.Fragment key={"sendable" + index}>
              <div className="col-12 col-lg-6">

                <SendableCard sendable={sendable} onClick={() => onCardClick(sendable.id)} />
              </div>
            </React.Fragment>
          )
        }
        )
        }
      </div>
    </div>
  )

}


// SendableList.propTypes = {
//   sendables: PropTypes.array
// }
// SendableList.defaultProps = {
//   sendables: []
// };

export default withRouter(SendableList)