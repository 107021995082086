import React, {useContext} from 'react'
import cx from 'classnames'
import { NavLink} from "react-router-dom"
import {OrderContext} from '../../context/OrderContext'
const StepTabs = (props) => {

    const { isStateComplete, getCurrentState } = useContext(OrderContext)

    if(getCurrentState() === 'customise') {
        return (
            <NavLink className={cx("btn btn-primary btn-icon-right btn-lg btn-block mb-5")} activeClassName='active' to="/Review">Back to Review</NavLink>
        )
    } else {
        return (

            <ul className={cx("nav nav-justified filter-tabs")}>
                
                <li className={cx("nav-item ",isStateComplete('sendable') ? "complete" : "")}>
                    <NavLink className={cx("nav-link")} activeClassName='active' to="/select">The Package</NavLink>
                </li>
                <li className={cx("nav-item",isStateComplete('sendable') ? "" : "disabled",isStateComplete('customer') ? "complete" : "")}>
                    <NavLink className={cx("nav-link")} activeClassName='active' to="/customer">The Customer</NavLink>
                </li>
                <li className={cx("nav-item",isStateComplete('customer') ? "" : "disabled",isStateComplete('customise') ? "complete" : "")}>
                    <NavLink className={cx("nav-link")} activeClassName='active' to="/customise">Personalise</NavLink>
                </li>
                {/* <li className={cx("nav-item")}>
                    <NavLink className={cx("nav-link")} activeClassName='active' to="/review">Ship</NavLink>
                </li> */}
            </ul>
        )
    }
    
}

export default StepTabs