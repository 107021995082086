import React, { useEffect, useState, useContext } from "react";
import styles from "./AppsPageStyles.module.sass";
import cx from "classnames";
import StepTabs from "../../components/StepTabs";

import { AppContext } from "../../context/AppContext";
import { OrderContext } from "../../context/OrderContext";
import InvitationEditor from "../../components/InvitationEditor";

/**
 * Apps Page
 * Configuration of the varia=ous apps enabled
 * @author Dave Ashenden <dave@thesubversivesagency.com>
 */
const AppsPage = props => {
  const { activeSendable } = useContext(AppContext);
  const {
    // customer,
    enabledApps,
    // invitation,
    // setAppEnabled,
    // setInvitation
  } = useContext(OrderContext);

  useEffect(
    () => {
      if (!enabledApps["invitation"]) {
        props.history.push('customise')
      }
    },
    []

  )
  return (
    <div className="page-wrapper">
      <StepTabs />
      <div
        className={cx(
          "container-fluid d-flex align-items-stretch align-content-stretch flex-column bd-highlight mb-3",
          styles.container
        )}
      >
        <div className="mb-auto row">
          <div className="container mt-1">
            <div className="row">
              <div className="col">
                <h2 className="title color-primary text-center ph-2">
                  We need a few details...
                </h2>
                {enabledApps["invitation"] && (
                  <div>
                    <InvitationEditor />
                  </div>
                )}
              </div>
            </div>

            <div className={cx("mb-auto row mt-5")}>
              <div className="col">
                <button
                  type="submit"
                  className="btn btn-primary btn-icon-right btn-lg btn-block"
                  onClick={() => props.history.push("customise")}
                >
                  Personalise the send &nbsp;
                  <i className="fa fa-2x fa-ship"> </i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppsPage;
