import React, { useEffect, useContext, useState } from "react";
import cx from "classnames";
import styles from "./styles.module.sass";

import {
  message,
  Form,
  Input,
  Button,
  Divider
} from "antd";
import { AppContext } from '../../context/AppContext'
import { OrderContext } from "../../context/OrderContext";
// import MessageRender from '../../commands/MessageRender/MessageRender';
// import ContentEditable from '../ContentEditable/ContentEditable';

const CustomerEditor = props => {
  const { activeSendable } = useContext(AppContext)
  const {
    customer,
    enabledApps,
    setAppEnabled
  } = useContext(OrderContext);

  const emailRequired = true
  // const [phoneRequired, setPhoneRequired] = useState(true)

  // -------------------
  // Effects
  // -------------------
  useEffect(() => {
    // console.log("CustomerEditor", customer);
  }, [customer]);

  // useEffect(() => {
  //   // console.log("CustomerEditor", customer);
  //   console.log("enabledApps", enabledApps);
  // });

  // -------------------
  // Handlers
  // -------------------

  // const handleFormChange = e => {
  //   console.log("handleFormChange", e);
  // };

  const handleConfirmPassword = (rule, value, callback) => {
    // const { getFieldValue } = props.form;
    console.log('handleConfirmPassword', rule, value, callback)
    // if (value && value !== getFieldValue("newPassword")) {
    //   callback("两次输入不一致！");
    // }

    // Note: 必须总是返回一个 callback，否则 validateFieldsAndScroll 无法响应
    callback();
  };

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        // console.log("Received values of form: ", values);
        // updateShipmentTracking(values.service, values["tracking-number"]);
        props.onSubmit(values);
      } else {
        message.error("Please provide the remaining customer details before submitting");
      }
    });
  };

  // /**
  //  * Handles the switching of the flow, addding or removing the invite flag from the order
  //  * @param {event} e 
  //  */
  // const handleInviteOrSendSwitch = e => {

  // }

  // const handleChange = e => {
  //   // console.log("changed", e.target.value)
  //   // props.onSubmit()
  // };

  // -------------------
  // Render
  // -------------------
  const { getFieldDecorator } = props.form;
  const is_digital = activeSendable ? activeSendable.is_digital : false;

  return (
    <div className={cx("container text-left", styles.container)}>
      <div className="row no-gutters">
        <div className="col-12 mt-5">
          {!enabledApps["invitation"] && !is_digital && (
            <div className="row">
              <div className="col text-center" style={{ fontSize: 12 }}>
                {/* <Card title="Don't know their address?"> */}
                Want to send but unsure of the address, email or text an invite
                instead...{" "}
                <Button
                  ghost
                  className="m-2"
                  type="primary"
                  style={{ fontSize: 12 }}
                  onClick={() => setAppEnabled("invitation", true)}
                >
                  Send an invite
                </Button>
                {/* </Card> */}
              </div>
            </div>
          )}

          {enabledApps["invitation"] && !is_digital && (
            <div className="row">
              <div className="col text-center" style={{ fontSize: 12 }}>
                {/* <Card title="Don't know their address?"> */}
                Got the Address?
                <Button
                  ghost
                  className="m-2"
                  type="primary"
                  style={{ fontSize: 12 }}
                  onClick={() => setAppEnabled("invitation", false)}
                >
                  Send By Mail
                </Button>
                {/* </Card> */}
              </div>
            </div>
          )}

          <Divider />
          <Form
            // {...formItemLayout}
            onSubmit={handleSubmit}
          // layout="horizontal"
          >
            <h5>Recipient:</h5>
            <div className="row">
              <div className="col-3 col-lg-2">
                <Form.Item hasFeedback>
                  {getFieldDecorator("salutation", {
                    initialValue: customer.salutation

                  })(<Input size="default" placeholder="Salutation" />)}
                </Form.Item>
              </div>
              <div className="col-9 col-lg-5">
                <Form.Item hasFeedback>
                  {getFieldDecorator("firstname", {
                    initialValue: customer.firstname,
                    rules: [
                      {
                        required: true,
                        message: "Please enter a firstname",
                        whitespace: true
                      }
                    ]
                  })(<Input size="default" placeholder="Firstname" />)}
                </Form.Item>
              </div>
              <div className="col-12 col-lg-5">
                <Form.Item hasFeedback>
                  {getFieldDecorator("lastname", {
                    initialValue: customer.lastname,
                    rules: [
                      {
                        required: true,
                        message: "Please enter a lastname for the custoemr",
                        whitespace: false
                      }
                    ]
                  })(<Input size="default" placeholder="Lastname" />)}
                </Form.Item>
              </div>
            </div>
            {/* <Divider /> */}
            <h5>Contact Details:</h5>
            <div className="row">
              <div className="col-8">
                <Form.Item hasFeedback>
                  {getFieldDecorator("email", {
                    initialValue: customer.email,
                    rules: [
                      {
                        type: "email",
                        message: "The input is not valid E-mail!"
                      },
                      {
                        required: emailRequired,
                        message: "Please input your E-mail!"
                      }
                    ]
                  })(<Input size="default" placeholder="Email" />)}
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item hasFeedback>
                  {getFieldDecorator("phone_number", {
                    initialValue: customer.phone_number,
                    rules: [
                      {
                        required: false,
                        message: "Please enter a phone number",
                        whitespace: false
                      }
                    ]
                  })(<Input size="default" placeholder="Phone Number" />)}
                </Form.Item>
              </div>
            </div>
            {/* TODO Ensure this is also the form for invite */}
            {!is_digital && !enabledApps["invitation"] && (
              <React.Fragment>
                {/* <Divider /> */}
                 <h5>Address:</h5>
                  
                <div className="row">
                  <div className="col">
                    <Form.Item hasFeedback>
                      {getFieldDecorator("company_name", {
                        initialValue: customer.company_name
                      })(<Input size="default" placeholder="Company Name / House Name" />)}
                    </Form.Item>
                  </div>
                  </div>
                <div className="row">
                  <div className="col">
                    <Form.Item hasFeedback>
                      {getFieldDecorator("line_1", {
                        initialValue: customer.line_1,
                        rules: [
                          {
                            required: true,
                            message: "Please enter a first line of the address",
                            whitespace: false
                          }
                        ]
                      })(<Input size="default" placeholder="Address Line 1" />)}
                    </Form.Item>
                  </div>
                  <div className="col">
                    <Form.Item hasFeedback>
                      {getFieldDecorator("line_2", {
                        initialValue: customer.line_2
                      })(<Input size="default" placeholder="Address Line 2" />)}
                    </Form.Item>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Form.Item hasFeedback>
                      {getFieldDecorator("town", {
                        initialValue: customer.town,
                        rules: [
                          {
                            required: true,
                            message: "Please enter a town / city",
                            whitespace: false
                          }
                        ]
                      })(<Input size="default" placeholder="Town / City" />)}
                    </Form.Item>
                  </div>
                  <div className="col">
                    <Form.Item hasFeedback>
                      {getFieldDecorator("county", {
                        initialValue: customer.county,
                        rules: [
                          {
                            required: true,
                            message: "Please enter a county / province",
                            whitespace: false
                          }
                        ]
                      })(<Input size="default" placeholder="County" />)}
                    </Form.Item>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Form.Item hasFeedback>
                      {getFieldDecorator("country", {
                        initialValue: customer.country,
                        rules: [
                          {
                            required: true,
                            message: "Please enter a country",
                            whitespace: false
                          }
                        ]
                      })(<Input size="default" placeholder="Country" />)}
                    </Form.Item>
                  </div>
                  <div className="col">
                    <Form.Item hasFeedback>
                      {getFieldDecorator("postcode", {
                        initialValue: customer.postcode,
                        rules: [
                          {
                            required: true,
                            message: "Please enter a Postal / Zip Code",
                            whitespace: false
                          }
                        ]
                      })(
                        <Input size="default" placeholder="Postal / Zip Code" />
                      )}
                    </Form.Item>
                  </div>
                </div>
              </React.Fragment>
            )}
            <div className="row">
              <div className="col">
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    // disabled={!dirty && constraint}
                    className="w-100"
                    size="large"
                  >
                    Personalise &nbsp; <i className="fa fa-arrow-right"> </i>
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Form.create({})(CustomerEditor);
