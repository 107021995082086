import React, { useEffect, useContext } from 'react'
import { AppConsumer, AppContext } from '../../context/AppContext'
import { OrderConsumer } from '../../context/OrderContext'
import StepTabs from '../../components/StepTabs';
import SendableList from '../../components/SendableList';
import SendableSearch from '../../components/SendableSearch';
import LoadingSpinner from '../../components/LoadingSpinner';
const Dashboard = (props) => {

    const appContext = useContext(AppContext)

    // const [selectedSendable, setSelectedSendable] = useState(null)

    useEffect(() => {
        // fire on load, redirect to dashboard/ last page if authorised
        // console.log('Dashboard',props, appContext)
        appContext.getSendables()
    }, [])

    return (
        <AppConsumer>
            {({ sendables, isLoading }) => (
                <OrderConsumer>
                    {({ customer, message, item }) => (
                        <div className="page-wrapper">

                            <StepTabs />
                            {isLoading && <LoadingSpinner />}
                            {!isLoading &&
                                <React.Fragment>
                                    <SendableSearch />
                                    <SendableList />
                                </React.Fragment>
                            }

                        </div>
                    )}
                </OrderConsumer>
            )}
        </AppConsumer>
    )
}

export default Dashboard
