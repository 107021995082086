import React, { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../../context/AuthContext';
// import LoginForm from '../../components/LoginForm';

import { message } from 'antd'
// import https://matter.ams3.cdn.digitaloceanspaces.com/static/matter-multiply-std.png from 'https://matter.ams3.cdn.digitaloceanspaces.com/static/matter-multiply-std.png'

const LoginPage = (props) => {

  // state

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  // }else{
  const [showError, setShowError] = useState(false)
  const [isValid, setIsValid] = useState(false)

  // programatic access to the auth consumer
  const auth = useContext(AuthContext)

  // effects
  useEffect(() => {
    // fire when the email or password are changed
    // console.log("EFFECT FIRED", isValid);
    validateForm()
  }, [email, password])

  useEffect(() => {
    // fire on load, redirect to dashboard/ last page if authorised
    // console.log('loginform',props)
    if (auth.isAuth) {
      props.history.push('/select')
    }
  })

  useEffect(() => {
    if (process.env.NODE_ENV !== "production") {
      setEmail("admin@matter.so");
      setPassword("password");
    }
  }, []);

  // handlers
  const handleEmail = e => {
    setEmail(e.target.value)
  }

  const handlePassword = e => {
    setPassword(e.target.value)
  }

  const handleSubmit = e => {
    e.preventDefault();
    setShowError(false)
    auth.login(email, password, handleAuthSuccess, handleAuthFailed)
  }

  const handleAuthFailed = (response) => {
    console.log('handleAuthFailed')
    // setShowError(true)
    // setTimeout( () => {setShowError(false)},10000)
    message.error("Unable to login with the provided details.")
  }

  const handleAuthSuccess = (response) => {
    // console.log('handleAuthSuccess', response)
    message.success("Login Successful")


  }

  // functions
  const validateForm = () => {
    // setIsValid(email.length > 0 && password.length > 0)
    setIsValid(true)
  }


  return (
    <div className="container">
      <div
        className="row justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="col-10">
          <div className="card border-0">
            <div className="card-body">
              <div className="text-center">
                <img
                  src="https://matter.ams3.cdn.digitaloceanspaces.com/static/matter-multiply-std.png"
                  className="img-fluid"
                  id="logo"
                  alt="Matter Logo"
                  style={{ maxWidth: 300, margin: "auto" }}
                />
              </div>
              <h4 className="text-center mb-5">
                Sign into your Matter account
              </h4>
              {showError && (
                <div class="alert alert-danger" role="alert">
                  Unable to login
                </div>
              )}
              <form action="" autoComplete="off">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control  form-control-lg"
                    name="email"
                    placeholder="Your username or email"
                    value={email}
                    onChange={handleEmail}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control form-control-lg"
                    name="password"
                    placeholder="password"
                    value={password}
                    onChange={handlePassword}
                  />
                </div>
                <button
                  type="submit"
                  id="sendlogin"
                  className="btn btn-primary btn-lg btn-block"
                  disabled={!isValid}
                  onClick={handleSubmit}
                >
                  login
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );


}

export default LoginPage
