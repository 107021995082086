import React, { useContext, useEffect } from "react";
// import styles from './styles.module.sass'
// import StepTabs from '../../components/StepTabs'
import SendableReview from "../../components/SendableReview";
import cx from "classnames";
import { AppContext } from "../../context/AppContext";
import { OrderContext } from "../../context/OrderContext";
// import MessagePreview from "../../components/MessagePreview";
import CustomerReview from "../../components/CustomerReview";

import OrderCreate from "../../commands/OrderCreate";
// import { toast } from "react-toastify";
import { message } from "antd";
import LoadingSpinner from "../../components/LoadingSpinner";
import PersonalisationSummary from "../../components/PersonalisationSummary";
import InvitationSummary from "../../components/InvitationSummary";
import NotesForm from "../../components/NotesForm";

const ReviewPage = props => {
  const { activeSendable, postOrder, setIsLoading, isLoading } = useContext(
    AppContext
  );
  const orderContext = useContext(OrderContext);
  const { enabledApps, invitation, sendable, lineItems } = orderContext;

  useEffect(() => {
    if (!orderContext.isStateComplete("sendable")) {
      props.history.push("/select");
    }

    if (!orderContext.isStateComplete("customer")) {
      props.history.push("/customer");
    }

    if (!orderContext.isStateComplete("customise")) {
      props.history.push("/customise");
    }
    console.log("SUMMARY INVITATION", invitation);
    orderContext.setSendable(activeSendable);
  }, []);

  const handleOnSubmit = () => {
    // console.log("handleOnSubmit", orderContext);
    setIsLoading(true);
    const order_data = orderContext.getOrder();
    // build the apps to be sent
    // TODO Clean this money pathcing
    let apps = {};
    if (orderContext.enabledApps["invitation"]) {
      // console.log( apps)
      apps["invitation"] = orderContext.invitation;
      // console.log( apps)
      // console.log( orderContext.invitation)
    }
    const order = OrderCreate(order_data, apps);
    console.log("Packet to create the order with...", order);
    postOrder(order, handleSubmitSuccess, handleSubmitFailure);
    console.log(order);
  };

  const handleSubmitSuccess = response => {
    // console.log('handleSubmitSuccess')
    // console.log(response)
    props.history.push("/complete");
    // orderContext.purge()
    setIsLoading(false);
    message.success("Your order has been submitted!");
  };

  const handleSubmitFailure = error => {
    // console.log('handleSubmitFailure')
    // console.log(error.response.status)
    setIsLoading(false);
    let out_message = "There has been an error submitting your order";
    switch (error.response.status) {
      case 409:
        out_message = "Oops! It appears you've already placed this order!";
        break;
      case 500:
        out_message = "Oops! Something went wrong at Matter HQ";
        break;
      case 422:
        out_message =
          "Oops! we had problems accepting your order, please check customer details and try again";
        break;
      default:
        out_message = "Something has gone wrong. We couldn't submit the order";
        break;
    }
    message.error(out_message);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  } else {
    return (
      <div className="page-wrapper">
        {/* <StepTabs/> */}
        <h2 className="title color-primary text-center ph-2">
          Great! One last check before we ship...
        </h2>
        <div
          className={cx(
            "container-fluid d-flex align-items-stretch align-content-stretch flex-column bd-highlight mb-3"
          )}
        >
          <div className={cx("mb-auto row")}>
            <div className="container mt-5">
              <div className="row">
                <div className="col">
                  {/* <h3 className=" mt-3 mb-3">You're sending:</h3> */}
                  <SendableReview
                    sendable={activeSendable}
                    className="half-height"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={cx("mb-auto row")}>
            <div className="container">
              <div className="row">
                <div className="col">
                  <h3 className=" mt-3 mb-3">Recipient:</h3>
                  <CustomerReview />
                </div>
              </div>
            </div>
          </div>

          <div className={cx("mb-auto row")}>
            <div className="container">
              {orderContext.enabledApps["invitation"] && (
                <div className="row">
                  <div className="col">
                    <h3 className=" mt-3 mb-3">
                      You are sending an invite as follows:
                    </h3>
                    <InvitationSummary invitation={invitation} onEdit={() => props.history.push('/apps')} />
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col">
                  <h3 className=" mt-3 mb-3">
                    {orderContext.enabledApps["invitation"]
                      ? "Upon reply, we'll send"
                      : "Send Elements:"}
                  </h3>
                  <PersonalisationSummary
                    sendable={sendable}
                    data={lineItems}
                    onEdit={() => props.history.push('/customise')}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={cx("mb-auto row")}>
            <div className="container">

              <div className="row">
                <div className="col">
                  <h3 className=" mt-3 mb-3">
                    Any notes for us?
                    <NotesForm />
                  </h3>

                </div>
              </div>
            </div>
          </div>

          <div className={cx("mb-auto row mt-5")}>
            <div className="col">
              <button
                type="submit"
                className="btn btn-primary btn-icon-right btn-lg btn-block"
                onClick={handleOnSubmit}
              >
                Ship It! &nbsp;
                <i className="fa fa-2x fa-ship"> </i>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ReviewPage;
