
import React from 'react'
import styles from './PersonalisationSummaryItemComponent.module.sass'
import cx from 'classnames'
import PropTypes from "prop-types";
import { skuKey } from '../../commands/JsonSchemaTools/CreateVariantField';
/*
 * PersonalisationSummaryItem Component 
 */
const PersonalisationSummaryItem = (props) => {
  const { data, variant } = props
  return (
    <div
      className={cx("personalisationsummaryitem-component", styles.container)}
    >
      <div className="row">
        {/* PersonalisationSummaryItem Component
        {JSON.stringify(item)}
        {JSON.stringify(data)} */}
        {/* {variant &&
          Object.keys(variant).map(key => {
            return (
              <div key={key} classNamee>
                <strong>{key}:</strong>
                <br /> {variant[key]}
              </div>
            );
          })} */}
        {Object.keys(data).map(key => {
          return key == skuKey ? null : (
            <div
              key={key}
              className={cx(
                "col-sm",
                Object.keys(data).length <= 1 ? "col-md-12" : "col-md"
              )}
            >
              <strong>{key}:</strong> {data[key]}
            </div>
          );
        })}
      </div>
    </div>
  );
}

PersonalisationSummaryItem.propTypes = {
  item: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

PersonalisationSummaryItem.defaultProps = {
  item: {},
  data: {}
};

export default PersonalisationSummaryItem


