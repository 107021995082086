
import React from 'react'
import styles from './PersonalisationSummaryComponent.module.sass'
import cx from 'classnames'


import PropTypes from "prop-types";
// import axios from "axios";

// import { AuthContext } from "../../context/AuthContext";
// import { AppContext } from "../../context/AppContext";
// import { OrderContext } from "../../context/OrderContext";

// import PersonalisationFormItem from "../PersonalisationFormItem";
// import LoadingContainer from "../LoadingContainer";
// import PersonalisationFormMessageWidget from "../PersonalisationFormMessageWidget";
import PersonalisationSummaryItem from '../PersonalisationSummaryItem';
import { skuKey } from '../../commands/JsonSchemaTools/CreateVariantField';

/*
 * PersonalisationSummary Component
 */
const PersonalisationSummary = props => {
 

  const {sendable,data} = props
  // our factory
  return (
    
      <div
        className={cx("personalisationformfactory-component", styles.container)}
      >
        {Object.keys(sendable.items).map((key, idx) => {
          const item = sendable.items[key];
          const datum = data[item.id];
          const variantSku = data[item.id][skuKey];
          const variant = item.variants[variantSku]

          
            return (
              <React.Fragment key={key}>
                <div className={cx("card my-3 ")}>
                  <div className="card-body ">
                    <div className="d-flex flex-row h-100">
                      <div className="d-flex flex-column">
                        <img
                          // height="100%"
                          // width="100%"
                          src={item.image_full}
                          alt=""
                          className={cx(
                            "card-image img-fluid mr-3 mb-3",
                            styles.image
                          )}
                        />
                      </div>
                      <div className="d-flex flex-column">
                        <h5> {item.name}</h5>
                        <PersonalisationSummaryItem
                          item={item}
                          data={datum}
                          variant={variant}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
        })}
        <div>
            <button className="btn btn-secondary btn-block mt-3" onClick={props.onEdit}>Change</button>
        </div>
      </div>
  );
};

PersonalisationSummary.propTypes = {
  data: PropTypes.object.isRequired,
  sendable: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
};

PersonalisationSummary.defaultProps = {
    onEdit: () => {}
}

export default PersonalisationSummary;


