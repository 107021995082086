import React from 'react'
import cx from 'classnames'
import styles from './NavBar.module.sass'
// import https://matter.ams3.cdn.digitaloceanspaces.com/static/matter-multiply-std.png from 'https://matter.ams3.cdn.digitaloceanspaces.com/static/matter-multiply-std.png'
// import { Collapse, NavbarToggler } from 'reactstrap'
import { Link } from 'react-router-dom'
import { AuthConsumer } from '../../context/AuthContext';
import { Avatar } from 'antd'

class NavBar extends React.Component {
  constructor(props) {
    super(props)

    this.toggleNavbar = this.toggleNavbar.bind(this)
    this.toggleAccountDropdown = this.toggleAccountDropdown.bind(this)
    this.state = {
      collapsed: true,
      account_dropdown: false
    }
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    })
  }

  toggleAccountDropdown() {
    this.setState({
      account_dropdown: !this.state.account_dropdown
    })
  }

  render() {
    return (
      <AuthConsumer>
        {({ isAuth, logout, currentUser }) =>
          isAuth && (
            <div className="container-fluid">
              <nav className={cx("navbar navbar-expand navbar-dark bg-white fixed-top",styles.container)}>
                <Link
                  to="/"
                  onClick={() => { }}
                  role="link"
                  className="mr-auto navbar-brand"
                >
                  <img
                    src="https://matter.ams3.cdn.digitaloceanspaces.com/static/matter-multiply-std.png"
                    className={cx("img-fluid", styles.logo)}
                    id="logo"
                    alt="Matter Logo"
                  />
                </Link>

                <ul className="nav navbar-nav flex-row float-right">
                  <li className="nav-item">
                    <Link
                      to="/"
                      onClick={() => {
                        this.toggleNavbar();
                      }}
                      role="link"
                      className="nav-link navigation__navlinks"
                    >
                      About
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <div
                      className={cx(
                        "nav-link dropdown-toggle p-0",
                        this.state.account_dropdown ? "show" : ""
                      )}
                      onClick={() => this.toggleAccountDropdown()}
                      id="navbarDropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {/* <img src="https://mdbootstrap.com/img/Photos/Avatars/avatar-5.jpg" className="rounded-circle z-depth-0" alt="avatar" height="40"/> */}
                      <Avatar
                        style={{
                          backgroundColor: "#666",
                          verticalAlign: "middle",
                          margin: "auto"
                        }}
                        size="large"
                      >
                        {currentUser.first_name.charAt(0).toUpperCase()}
                        {currentUser.last_name.charAt(0).toUpperCase()}
                      </Avatar>
                    </div>
                    <div
                      className={cx(
                        "dropdown-menu dropdown-menu-right",
                        this.state.account_dropdown ? "show" : ""
                      )}
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      {/* <h6 className="dropdown-header">Profile</h6>*/}

                      <a href="https://hq.matter.so/"
                        className="dropdown-item"
                        target="_blank"

                      >
                        Dashboard
                      </a>
                      {/* 
                      <button
                        className="dropdown-item"
                        onClick={() => {
                          this.toggleNavbar();
                          logout();
                        }}
                      >
                        Your Profile
                      </button> */}
                      {/* <h6 className="dropdown-header">Session</h6> */}
                      <button
                        className="dropdown-item"
                        onClick={() => {
                          this.toggleNavbar();
                          logout();
                        }}
                      >
                        Logout
                      </button>
                    </div>
                  </li>
                </ul>
              </nav>
            </div>
          )
        }
      </AuthConsumer>
    );
  }
}

export default NavBar
