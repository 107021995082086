import React, { useEffect } from "react";
import { MemoryRouter as Router, Route, Redirect } from "react-router-dom";
// import '../../node_modules/font-awesome/css/font-awesome.min.css'
import "./sass/index.sass";
import "./less/ant.less";
import Layout from "./components/layout";
import Login from "./pages/login";
import Dashboard from "./pages/dashboard";
import Personalise from "./pages/personalise";
import Customer from "./pages/customer";
import Review from "./pages/review";
import StepManager from "./components/StepManager";
import { OrderProvider } from "./context/OrderContext";
import { AuthProvider, AuthConsumer } from "./context/AuthContext";
import { AppProvider } from "./context/AppContext";
import LoadingSpinner from "./components/LoadingSpinner";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { message } from "antd";
import PostMessageComponent from "./components/PostMessageComponent";
import Complete from "./pages/Complete";
import * as Sentry from "@sentry/browser";
import Apps from "./pages/Apps";

if (process.env.NODE_ENV === "production") {
  console.log = () => { };

  Sentry.init({
    dsn: "https://f3d7bbf888a74a5684086f31ad495c33@sentry.io/1527018"
  });
}

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <AuthConsumer>
      {({ isAuth, isLoading }) => (
        <Route
          {...rest}
          render={props =>
            isAuth ? (
              <Component {...props} />
            ) : (
                <Redirect
                  to={{
                    pathname: "/login",
                    state: { from: props.location }
                  }}
                />
              )
          }
        />
      )}
    </AuthConsumer>
  );
}

const App = () => {
  // const myListener = new PostMessageListener(useContext(OrderContext))

  useEffect(() => {
    // console.log("MATTER WIDGET LOAD")
    message.config({
      top: 100,
      duration: 6
    });
  }, []);

  return (
    <AuthProvider>
      <AppProvider>
        <OrderProvider>
          <AuthConsumer>
            {({ isAuth, isLoading }) =>
              isLoading ? (
                <LoadingSpinner />
              ) : (
                  <TransitionGroup>
                    <CSSTransition key="fade" timeout={{ enter: 300, exit: 300 }}>
                      <Router>
                        <Layout>
                          {/* <ToastContainer
                          position="top-center"
                          autoClose={7000}
                          hideProgressBar={true}
                          newestOnTop={false}
                          closeOnClick
                          rtl={false}
                          pauseOnVisibilityChange={false}
                          draggable={false}
                          pauseOnHover={false}
                        /> */}
                          <StepManager />
                          <PostMessageComponent />
                          <PrivateRoute
                            exact
                            path="/select"
                            component={Dashboard}
                          />
                          <PrivateRoute
                            exact
                            path="/customise"
                            component={Personalise}
                          />
                          <PrivateRoute
                            exact
                            path="/apps"
                            component={Apps}
                          />
                          <PrivateRoute
                            exact
                            path="/customer"
                            component={Customer}
                          />
                          <PrivateRoute exact path="/review" component={Review} />
                          <PrivateRoute
                            exact
                            path="/complete"
                            component={Complete}
                          />
                          {/* <PrivateRoute exact path='/' component={Complete} /> */}
                          <PrivateRoute exact path="/" component={Login} />
                          <Route path="/login" component={Login} />
                        </Layout>
                      </Router>
                    </CSSTransition>
                  </TransitionGroup>
                )
            }
          </AuthConsumer>
        </OrderProvider>
      </AppProvider>
    </AuthProvider>
  );
};

export default App;
