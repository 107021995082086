import {useEffect} from 'react'
import {withRouter} from 'react-router-dom';
// import { AppContext } from '../../context/AppContext';
// import {OrderContext} from '../../context/OrderContext'

/*
 * StepManager Component 
 */
const StepManager = (props) => {
    useEffect(
        () => {
            // if (props.location.pathname !== prevLoc.pathname) {
                window.scrollTo(0, 0);
            // }
        },
        [props.location.pathname]
    ) 


    return null

}

export default withRouter(StepManager)


