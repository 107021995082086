import { getFrameElement } from "../../matter-extension/src/utils/elements";
export var buildPostMessage = function buildPostMessage(messageType, content) {};
export var sendPostMessage = function sendPostMessage(messageType, content) {};
export var sendPostMessageToParent = function sendPostMessageToParent(messageType, data) {
  window.parent.postMessage({
    action: messageType,
    body: data
  }, '*');
};
export var sendPostMessageToChild = function sendPostMessageToChild(messageType, content) {
  var frame = getFrameElement(); // console.log("sendPostMessageToChild",frame, messageType, content)

  frame.contentWindow.postMessage({
    action: messageType,
    body: content
  }, "*");
};