
import React from 'react'
/*
 * LoadingSpinner Component 
 */
const LoadingSpinner = (props) => (
    <div className="container h-100">
        <div className="row h-100">
            <div className="col-12  h-100 ">
                <div className="spinner-container">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>

)

export default LoadingSpinner


