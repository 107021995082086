
import React, {useContext} from 'react'
import styles from './styles.module.sass'
import cx from 'classnames'
import { NavLink} from "react-router-dom"

// contexts

import {OrderContext} from '../../context/OrderContext'


//components

/*
 * CustomerReview Component 
 */
const CustomerReview = (props) => { 

    // context
    const {customer} = useContext(OrderContext)
    
    // local
    
    
    
    // useEffect(
    //     () => {
    //         const previewMessage =  MessageRender(message, substitutions)
    //         if(previewElement) {
    //             previewElement.innerHTML = previewMessage.replace(/(?:\r\n|\r|\n)/g, '<br>');
    //         }
    //     }, [previewElement,message]
    // );
    const {
            salutation, 
            firstname,
            lastname, 
            email, 
            phone_number,
            line_1,
            line_2,
            postcode,
            town,
            county
        
        } = customer

    const address =  [
            [salutation, 
            firstname,
            lastname].join(" "), 
            line_1,
            line_2,
            town,
            county,
            postcode
        ].filter(Boolean).join(',\n<br/>')
    
    // localStorage.clear()
    return (
        <div className={cx("card border-bottom-0", styles.previewCard)}>
            
            <div className="card-body">
            
            <div className="row">
                <div className="col">
                    <h5>Address</h5>

                    
<div dangerouslySetInnerHTML={{__html: address}} />

                </div>
                <div className="col">
                    <h5>Contact details</h5>
                    <dl>
                        <dt>Phone</dt>
                        <dd>{phone_number}</dd>
                        <dt>Email</dt>
                        <dd>{email}</dd>
                    </dl>
                    
                </div>
            </div>
            
            </div>
            <div className="row">
                <div className="col">
                    <NavLink className="btn btn-small icon-left btn-secondary  btn-block" activeClassName='active' to="/customer">
                      <i className="fa fa-pencil"></i> Edit
                    </NavLink>
                </div>
            </div>

        </div>

    )
}

export default CustomerReview


