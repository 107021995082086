import React from 'react'
import cx from 'classnames'
import styles from './styles.module.sass'
import PropTypes from 'prop-types'
import { NavLink} from "react-router-dom"

const SendableReview = (props) => {

  const {sendable} = props

  return (
      <div className={cx("card bg-light text-white border-0",styles.card, props.className)} onClick={props.onClick}>
        <div className={cx("card-body ",styles.overlay)}>
            <div className="row">
                <div className="col-3"><img height="100%" width="100%" src={sendable.image_full} alt="" className={cx("card-image-top img-fluid",styles.default)}/></div>
                <div className="col">
                    {/* <div className={cx("card-tag-list",styles.tags)}>
                      {sendable.tag_list.map(
                          (tag,idx) => {
                              return ( <small className={cx("badge badge-sm bg-dark my-2 mr-2 p-2",styles.badge)} key={sendable.id + "-tag-" + tag}>{tag}</small> )
                          }

                      )}
                    </div> */}
                    
                    <h4 className={cx("card-title text-center pt-1 pb-1",styles.title)}>{sendable.name}</h4>
                    <NavLink className="btn btn-small icon-left btn-secondary btn-block" activeClassName='active' to="/select">
                      <i className="fa fa-pencil"></i> Edit
                    </NavLink>
                </div>
            </div>
        
            
            
        </div>
      </div>
  )
}


SendableReview.propTypes = {
  sendable: PropTypes.object.isRequired,
  isSelected: PropTypes.bool
}
SendableReview.defaultProps = {
  isSelected: false,
  onclick: () => null
}

export default SendableReview