import React, { useState, useContext } from "react";

import { AuthContext } from './AuthContext';
// import {useLocalState} from '../commands/LocalStorage'

const OrderContext = React.createContext();

const OrderProvider = props => {

  const authContext = useContext(AuthContext)

  // const intialCustomer = {
  //   external_id: "",
  //   meta: {},
  //   salutation: "Mr",
  //   firstname: "Test",
  //   lastname: "Username",
  //   line_1: "20 Parkside Road",
  //   line_2: "",
  //   town: "Exeter",
  //   county: "Devon",
  //   country: "UK",
  //   country_code: "UK",
  //   postcode: "ex1 3tn",
  //   phone_number: "0770933376",
  //   email: "shoesofwonder@gmail.com",
  //   external_source: "",
  //   source_id: "widget"

  // }
  // const intialCustomer = {
  //  country: "United Kindgom",
  //   country_code: "",
  //   county: "Devon",
  //   email: "daveashenden@gmail.com",
  //   external_id: "0034J000003h3LHQAY",
  //   firstname: "Saleforce",
  //   lastname: "Imported User",
  //   line_1: "20 Parkside Road",
  //   line_2: "",
  //   meta: {type: "Contact", url: "/services/data/v43.0/sobjects/Contact/0034J000003h3LHQAY"},
  //   phone_number: "07709339746",
  //   postcode: "EX1 3TN",
  //   salutation: "Mr.",
  //   source_id: "salesforce",
  //   town: "Exeter"

  // }
  const intialCustomer = {
    external_id: "",
    source_id: "widget",
    meta: {},
    salutation: "",
    firstname: "",
    lastname: "",
    company_name: "",
    line_1: "",
    line_2: "",
    town: "",
    county: "",
    country: "",
    country_code: "",
    postcode: "",
    phone_number: "",
    email: ""
  }
  // : {
  //     external_id: "",
  //     meta: {},
  //     salutation: "Mr",
  //     firstname: "Test",
  //     lastname: "Username",
  //     line_1: "20 Parkside Road",
  //     line_2: "",
  //     town: "Exeter",
  //     county: "Devon",
  //     country: "UK",
  //     country_code: "UK",
  //     postcode: "ex1 3tn",
  //     phone_number: "0770933376",
  //     email: "shoesofwonder@gmail.com",
  //     external_source: "",
  //     source_id: "widget"
  //   };

  const invitationBase = {
    expires_in_days: 14,
    delivery_method: "email",
    message: "Great to speak earlier, I'd love to buy you a coffee !firstname. Could you click the link below to tell me your address. \nCheers\n!user_firstname"
  };

  // =========================
  // DATUM
  // =========================
  // the sendable
  const [sendable, setSendable] = useState(null);
  // item item
  const [lineItems, setLineItems] = useState({});
  // message
  const [message, setMessage] = useState("");
  const [notes, setNotes] = useState("");
  // invitation
  const [enabledApps, setEnabledApps] = useState({});
  const [invitation, setInvitation] = useState(invitationBase);

  // customer
  const [customer, setCustomer] = useState(intialCustomer);

  const setAppEnabled = (app, enabled) => {
    console.log("setAppEnabled", app, enabled);
    let apps = {};
    if (enabled) {
      apps[app] = true
    } else {
      apps[app] = false
    }
    setEnabledApps({ ...enabledApps, ...apps })
  }

  const updateCustomer = data => {
    setCustomer({ ...customer, ...data });
  };
  // =========================
  // STATE MACHINE
  // =========================

  // state enum
  const states = ["sendable", "customer", "customise", "confirm", "complete"];
  const [currentState, setCurrentState] = useState(-1);

  // get the current state
  const getCurrentState = () => {
    return states[currentState];
  };

  const getStateIndex = () => {
    return states.indexOf(currentState);
  };

  // check that a step is complete
  const isStateComplete = state => {
    if (states.indexOf(state) === -1) return false;
    if (states.indexOf(state) <= currentState) return true;
  };

  /**
   * increment to the next step
   * @param {string} activeState the state to go to
   */
  const incrementState = activeState => {
    // console.log(activeState,currentState, states.indexOf(activeState),currentState < states.indexOf(activeState))
    if (currentState < states.indexOf(activeState)) {
      const newState = currentState + 1;
      // console.log('incrementState', currentState, newState)
      setCurrentState(newState);
      return states[newState];
    }
  };

  /**
   * Sets the sendable in state whilst then building out the line items from it
   * @param {Object} newsendable the active sendable
   */
  const setSendableInternal = newsendable => {
    // console.log('setSendableInternal')
    // // set the touch point
    if (newsendable !== sendable) {
      setSendable(newsendable);

      let newLineItems = {};
      // create the order items from the items
      newsendable.items.map(item => {
        // console.log("CREAING SENDABLE ", item.id, item.name, item)
        newLineItems[item.id] = item.configuration;
        return newLineItems[item.id];
      });
      // console.log(newLineItems)
      setLineItems(newLineItems);
    }
  };

  /**
   * Updates a line item in the state
   * @param {uuid} id Identification of the item item
   * @param {object} data the configuration pack
   */
  const updateLineItem = (id, data) => {
    const newLineItems = { ...lineItems, [id]: data };

    // console.log("updateLineItem", newLineItems);
    setLineItems(newLineItems);
  };

  /**
   * Move the sku out of the configuration
   */
  const mergeVariants = () => {
    let tmpLineItems = {};
    Object.keys(lineItems).map(k => {
      const li = lineItems[k];
      let newData = li;
      delete newData["variant"];
      tmpLineItems[k] = { sku: li["sku"], data: newData };
    });
    setLineItems(tmpLineItems);
  };

  /**
   * Compiles  the order into one package for external use
   */
  const getOrder = () => {
    return { sendable, lineItems, customer, state: currentState, notes: notes };
  };

  /**
   * Purges the state of the order backet to the first boot
   */
  const purge = () => {
    setSendable(null);
    setLineItems({});
    setCustomer(intialCustomer);
    setCurrentState(-1);
  };

  /**
   * return of the provider with public funcs
   */
  return (
    <OrderContext.Provider
      value={{
        // state
        notes, setNotes,
        states,
        currentState,
        getCurrentState,
        getStateIndex,
        isStateComplete,
        incrementState,
        updateLineItem,
        // datum
        sendable,
        lineItems,
        message,
        customer,
        setSendable: setSendableInternal,
        setLineItems,
        setMessage,
        setCustomer,
        updateCustomer,
        //TOP LEVEL
        getOrder,
        purge,
        setInvitation,
        invitation,
        enabledApps,
        setAppEnabled
      }}
    >
      {props.children}
    </OrderContext.Provider>
  );
};

const OrderConsumer = OrderContext.Consumer;

export { OrderContext, OrderProvider, OrderConsumer };
