import axios from "axios";
import PubSub from "pubsub-js";
import { loadLocal } from "../commands/LocalStorage";
import { ConcurrencyManager } from "axios-concurrency"
// EVENTS FOR PUB SUB

export const API_EVENT_REQUEST = "API_EVENT_REQUEST";
export const API_EVENT_RESPONSE = "API_EVENT_RESPONSE";
export const API_EVENT_REQUEST_SUCCESS = "API_EVENT_REQUEST_SUCCESS";
export const API_EVENT_REQUEST_FAILED = "API_REQUEST_FAILED";

export const objectToParams = params =>
  Object.keys(params)
    .map(key => key + "=" + params[key])
    .join("&");

export const Api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
  transformRequest: [
    function (data, headers) {
      headers["Authorization"] = "Bearer " + loadLocal("token", false);
      return JSON.stringify(data);
    }
  ],
  headers: {
    "Content-Type": "application/json"
  }
});

// a concurrency parameter of 1 makes all api requests secuential
const MAX_CONCURRENT_REQUESTS = 2 //process.env.REACT_APP_API_CONCURRENCY || 5;

// init your manager.
const manager = ConcurrencyManager(Api, MAX_CONCURRENT_REQUESTS);

export const devNull = () => null;

/**
 * service for the Sendables API aspect of matter
 */
export const CrudApi = endpoint => {
  // const API = ()
  // GET /Sendables
  const list = (
    successCallback = devNull,
    failureCallback = devNull,
    params = null
  ) => {
    PubSub.publish(API_EVENT_REQUEST);

    Api.get(endpoint + (params ? "?" + objectToParams(params) : ""))
      .then(response => {
        PubSub.publish(API_EVENT_RESPONSE);
        if (successCallback) successCallback(response);
      })
      .catch(error => {
        PubSub.publish(API_EVENT_RESPONSE);
        if (failureCallback) failureCallback(error);
      });
  };

  // GET /Sendables/:id
  const read = (id, successCallback = devNull, failureCallback = devNull) => {
    PubSub.publish(API_EVENT_REQUEST);
    if (!id) {
      PubSub.publish(API_EVENT_RESPONSE);
    } else {
      Api.get(endpoint + "/" + id)
        .then(response => {
          PubSub.publish(API_EVENT_RESPONSE);
          successCallback(response);
        })
        .catch(error => {
          PubSub.publish(API_EVENT_RESPONSE);
          failureCallback(error);
        });
    }
  };

  // POST /Sendables
  const create = (
    params,
    successCallback = devNull,
    failureCallback = devNull
  ) => {
    PubSub.publish(API_EVENT_REQUEST);
    Api.post(endpoint, params)
      .then(response => {
        PubSub.publish(API_EVENT_RESPONSE);
        successCallback(response);
      })
      .catch(error => {
        PubSub.publish(API_EVENT_RESPONSE);
        failureCallback(error);
      });
  };

  // POST /Sendables/:id
  const update = (
    id,
    params = null,
    successCallback = devNull,
    failureCallback = devNull
  ) => {
    Api.put(endpoint + "/" + id, params)
      .then(response => {
        PubSub.publish(API_EVENT_RESPONSE);
        successCallback(response);
      })
      .catch(error => {
        PubSub.publish(API_EVENT_RESPONSE);
        failureCallback(error);
      });
  };

  // DELETE /Sendables/:id
  const remove = (
    id,
    successCallback = devNull,
    failureCallback = devNull
  ) => {
    Api.delete(endpoint + "/" + id)
      .then(response => {
        PubSub.publish(API_EVENT_RESPONSE);
        successCallback(response);
      })
      .catch(error => {
        PubSub.publish(API_EVENT_RESPONSE);
        failureCallback(error);
      });

  };

  return {
    ...Api,
    list,
    create,
    read,
    update,
    remove
  };
};


/**
 * service for the Sendables API aspect of matter
 */
export const CrudSubApi = (parentPrefix, endpoint) => {
  // const API = ()
  // GET /Sendables
  const list = (
    parent_id,
    successCallback = devNull,
    failureCallback = devNull,
    params = null
  ) => {
    // PubSub.publish(API_EVENT_REQUEST);
    console.log("IODSFJKLASJF", parentPrefix + "/" + parent_id + "/" + endpoint)
    Api.get(parentPrefix + "/" + parent_id + "/" + endpoint + (params ? "?" + objectToParams(params) : ""))
      .then(response => {
        // PubSub.publish(API_EVENT_RESPONSE);
        if (successCallback) successCallback(response);
      })
      .catch(error => {
        // PubSub.publish(API_EVENT_RESPONSE);
        if (failureCallback) failureCallback(error);
      });
  };

  // GET /Sendables/:id
  const read = (parent_id, id, successCallback = devNull, failureCallback = devNull) => {
    // PubSub.publish(API_EVENT_REQUEST);
    if (!id) {
      // PubSub.publish(API_EVENT_RESPONSE);
    } else {
      Api.get(parentPrefix + "/" + parent_id + "/" + endpoint + "/" + id)
        .then(response => {
          // PubSub.publish(API_EVENT_RESPONSE);
          successCallback(response);
        })
        .catch(error => {
          // PubSub.publish(API_EVENT_RESPONSE);
          failureCallback(error);
        });
    }
  };

  // POST /Sendables
  const create = (
    parent_id,
    params,
    successCallback = devNull,
    failureCallback = devNull
  ) => {
    // PubSub.publish(API_EVENT_REQUEST);
    Api.post(parentPrefix + "/" + parent_id + "/" + endpoint, params)
      .then(response => {
        // PubSub.publish(API_EVENT_RESPONSE);
        successCallback(response);
      })
      .catch(error => {
        // PubSub.publish(API_EVENT_RESPONSE);
        failureCallback(error);
      });
  };

  // POST /Sendables/:id
  const update = (
    parent_id,
    id,
    params = null,
    successCallback = devNull,
    failureCallback = devNull
  ) => {
    Api.put(parentPrefix + "/" + parent_id + "/" + endpoint + "/" + id, params)
      .then(response => {
        // PubSub.publish(API_EVENT_RESPONSE);
        successCallback(response);
      })
      .catch(error => {
        // PubSub.publish(API_EVENT_RESPONSE);
        failureCallback(error);
      });
  };

  // DELETE /Sendables/:id
  const remove = (
    parent_id,
    id,
    successCallback = devNull,
    failureCallback = devNull
  ) => {
    Api.delete(parentPrefix + "/" + parent_id + "/" + endpoint + "/" + id)
      .then(response => {
        // PubSub.publish(API_EVENT_RESPONSE);
        successCallback(response);
      })
      .catch(error => {
        // PubSub.publish(API_EVENT_RESPONSE);
        failureCallback(error);
      });

  };

  return {
    parentPrefix,
    endpoint,
    ...Api,
    list,
    create,
    read,
    update,
    remove
  };
};