import React, { useState, useEffect, useContext } from "react";
import styles from "./PersonalisationFormFactoryComponent.module.sass";
import cx from "classnames";
import { Spin } from 'antd';
import PropTypes from "prop-types";
import axios from "axios";
import formatsJson from '../../data/formats.json'

// import { AuthContext } from "../../context/AuthContext";
// import { AppContext } from "../../context/AppContext";
import { OrderContext } from "../../context/OrderContext";

import PersonalisationFormItem from "../PersonalisationFormItem";
import LoadingContainer from "../LoadingContainer";
import PersonalisationFormMessageWidget from "../PersonalisationFormMessageWidget";

/*
 * PersonalisationFormFactory Component
 */
const PersonalisationFormFactory = props => {
  const schemasUrl = process.env.REACT_APP_API_BASE + "playbook/formats/";

  // props
  const { formConfiguration, sendable, data } = props;
  // state3
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  // const [isError, setIsError] = useState(false);
  const [schemas, setSchemas] = useState(null);

  // Contexts
  // const { currentUser } = useContext(AuthContext);
  // const { activeSendable } = useContext(AppContext);
  const { updateLineItem } = useContext(OrderContext);

  // // form shizzle
  // const sharedFormConfig = {
  //   mergeFields: {}
  // };

  const widgets = {
    "matter:messageWidget": PersonalisationFormMessageWidget
  };

  const hasErrors = () => {
    let err = false;
    Object.keys(errors).map(key => {
      if (errors[key] === true) {
        err = true;
      }
      return err
    });
    // setIsError(err)
    return err;
  };

  const hasError = key => {
    return errors[key] === true;
  };

  // on boot
  useEffect(() => {
    console.log("GET SCHEMA LOAD")
    // grab the schema
    getSchema();
  }, []);

  // useEffect(() => {
  //   console.log("setErrors Callback", hasErrors());
  // });

  // form callbacks
  // hndlers for props
  const onChange = (id, data) => {
    let newErrors = errors;

    // console.log("FACTORY changed", id, data)
    newErrors[id] = data.errors.length > 0;
    setErrors(newErrors);
    const err = hasErrors();
    // console.log("hasErrors", err)
    props.hasErrors(err);
    // setIsError(err);

    // TODO Here we need to check the balance available

    // if(data.errors.length === 0) {
    updateLineItem(id, data.formData);
    // }
  };
  const onSubmit = (id, data) => {
    console.log("PersonalisationFormFactory::onSubmit", id, data);

    // TODO: reintegrate the variant options we truncated in the form for each item like ninjas
    // sendable.items.map(
    //   (item) => {}
    // )

    if (data.errors.length < 0) {
      updateLineItem(id, data.formData);
    } else {
    }
  };
  const onError = (id, data) => {
    // console.log("error", id, data);
  };

  // fetches the schema for types from the server
  const getSchema = () => {
    // console.log("getSchema")
    // setLoading(true);
    // axios
    // .get(schemasUrl, {
    //   //   headers: { Authorization: localStorage.getItem("token") }
    // })
    // .then(response => {
    //   // console.log("getSchema Success", response)
    //     // console.log("GET CAMPAIGNS RESPONSE")
    //     if (response.status === 200) {
    //       // console.log('SUCCESS', response.data);
    //       setSchemas(response.data);
    //     } else {

    //       setSchemas(null);
    //     }
    //     setLoading(false);
    //   })
    //   .catch(e => {

    //   // console.log("getSchema Fail", e)
    //     setLoading(true);
    //   });
    setLoading(false);
    setSchemas(formatsJson);
  };



  // our factory
  return (
    <LoadingContainer loading={loading}>
      <Spin spinning={loading}>
        <div className="d-block h-100" style={{ minHeight: 500 }}>
          {!loading && schemas != null && <div
            className={cx("personalisationformfactory-component", styles.container)}
          >
            {Object.keys(sendable.items).map((key, idx) => {

              const item = sendable.items[key];
              const datum = data[item.id];

              const schema = schemas[item.product_type]
                ? JSON.parse(JSON.stringify(schemas[item.product_type].operator))
                : {};

              {/* if (item.product_type !== "uncustomisable") { */ }
              return (
                <React.Fragment key={key}>
                  {/* {hasError(item.id) ? "YES" : "NO"} */}
                  <div
                    className={cx(
                      "card m-3 ",
                      hasError(item.id) ? "text-white border-primary" : ""
                    )}
                    style={["uncustomisable", "packaging"].indexOf(item.product_type) > -1 ? { display: 'none' } : {}}
                  >

                    <div className="card-body ">
                      <div className="d-flex flex-row h-100">
                        <div className="d-flex flex-column">
                          <img
                            // height="100%"
                            // width="100%"
                            src={item.image_full}
                            alt=""
                            className={cx(
                              "card-image img-fluid mr-3 mb-3",
                              styles.image
                            )}
                          />
                        </div>
                        <div className="d-flex flex-column">
                          <h5> {item.name}</h5>
                        </div>
                      </div>
                      {/* <h6> {item.id}</h6> */}
                      <PersonalisationFormItem
                        item={item}
                        data={datum}
                        schemas={schema}
                        widgets={widgets}
                        formConfiguration={formConfiguration}
                        onChange={onChange}
                        onSubmit={onSubmit}
                        onError={onError}
                      />
                    </div>
                  </div>
                </React.Fragment>
              );
              {/* } else {
            return null;
          } */}
            })}
          </div>}
        </div>
      </Spin>
    </LoadingContainer>
  );
};

PersonalisationFormFactory.propTypes = {
  data: PropTypes.object.isRequired,
  sendable: PropTypes.object.isRequired,
  formConfiguration: PropTypes.object.isRequired,
  hasErrors: PropTypes.func.isRequired
};

export default PersonalisationFormFactory;
