/**
 * MessageRender Command
 * @desc Merges a message template with values provided for terms 
 * @author: Dave Ashenden 
 * @param template String message template
 * @param terms Hash of substitutions
 */
const MessageRender = (template, terms) => {
    
    const re = new RegExp("!" + Object.keys(terms).join("|!").toUpperCase(),"gi");
    const result = template.replace(re, function(matched){
        return "" + terms[matched.substring(1).toLowerCase()] + "";
    })
    
    return result
}

export default MessageRender
