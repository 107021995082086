import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { useLocalState } from "../commands/LocalStorage";
import { saveLocal, loadLocal } from '../commands/LocalStorage';
import { Api } from '../services/API';
// import { Api } from "../services    /API";
// import useReactRouter from '../lib/use-react-router'

// const loginUrl = process.env.REACT_APP_API_BASE + 'login'
const loginUrl = process.env.REACT_APP_API_BASE + 'oauth/token'
const refreshUrl = process.env.REACT_APP_API_BASE + 'auth/refresh'
const logoutUrl = process.env.REACT_APP_API_BASE + 'logout'
// const resetUrl = process.env.REACT_APP_API_BASE+'password'
const AuthContext = React.createContext();

const AuthProvider = (props) => {

    const [isLoading, setIsLoading] = useState(true)
    const [isAuth, setIsAuth] = useState(false)
    const [token, setToken] = useLocalState("token", null, false); //useState(localStorage.getItem('token'))
    // const [currentUser, setCurrentUser] = useState(loadLocal('user', true)))
    // const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem('user')))
    const [currentUser, setCurrentUser] = useState(null);

    // authorisation functions
    const authorise = () => setIsAuth(true)
    const deauthorise = () => setIsAuth(false)

    const authHeaders = () => {return { "Authorization": "Bearer " + token }}

    const logout = () => {
        axios.delete(logoutUrl, { headers: { "Authorization": token } }).then(
            response => {
                clearLogin();
            }
        ).catch(

            (e) => {
                clearLogin();
            }
        )

    }

    // referesh
    const refresh = () => {
        // console.log("AuthContext.refresh")
        // setIsLoading(true)
        Api.get(refreshUrl)
        .then(response => {
          if (response.statusText === "OK") {
            authorise();
  
            setCurrentUser(response.data.result)
            // setToken(response.headers.authorization)
  
            // console.log("Authorised");
            // success(response);
          } else {
            // console.log("NOT Authorised");
            deauthorise();
            clearLogin();
            // failure(response);
          }
          setIsLoading(false);
        })
        .catch(e => {
          // console.log("something went wrong with the", e);
          clearLogin();
          // failure(e);
        });
        // axios.get(refreshUrl, { headers: {  "Authorization": "Bearer " + token } })
        //     .then(
        //         response => {

        //             if (response.statusText === 'OK') {
        //                 authorise()

        //                 setCurrentUser(response.data.result)
        //                 // console.log("Authorised")
        //                 // success(response);
        //             } else {
        //                 clearLogin();
        //                 // console.log("NOT Authorised")
        //                 // failure(response);
        //             }
        //             setIsLoading(false)
        //         }
        //     ).catch(

        //         (e) => {
        //             // console.log('something went wrong', e)
        //             clearLogin();
        //             // failure(e);

        //         }
        //     )
    }

    //  login
    const login = (email, password, success = () => { }, failure = () => { }) => {
        // console.log("AuthContext.login")
        setIsLoading(true)
        axios.post(loginUrl, {

            email: email,
            password: password,
            grant_type: "password"

        })
            .then(
                response => {

                    if (response.statusText === 'OK') {
                        // localStorage.setItem("token", response.headers.authorization);
                        // localStorage.setItem("user", JSON.stringify(response.data));
                        // saveLocal('user', response.data, true);
                        console.log("login response", response.data, response.data.access_token)
                        // setCurrentUser(response.data)
                        // setToken(response.headers.authorization)
                        setCurrentUser(response.data.result);
                        setToken(response.data.access_token);
                        authorise()
                        success(response);
                        setIsLoading(false)
                    } else {
                        clearLogin();
                        failure(response);
                    }
                }
            ).catch(

                (e) => {
                    // console.log('something went wrong', e)
                    clearLogin();
                    failure(e);
                }
            )
    }


    // purge login data
    const clearLogin = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        deauthorise()
        setIsLoading(false)
    }


    useEffect(() => {
        // console.log('Authprovide effect', token)
        refresh()
        // TODO: check for token
        // TODO: fire off login
        // TODO:
    }, [])

    return (
        <AuthContext.Provider
            value={{
                isAuth: isAuth,
                authorise: authorise,
                deauthorise: deauthorise,
                login: login,
                refresh: refresh,
                logout: logout,
                isLoading: isLoading,
                token,
                currentUser,
                setCurrentUser,
                authHeaders
            }}
        >
            {/* <p onClick={authorise}>Curently {isAuth ? "YES": "NO"}</p> */}
            {props.children}
        </AuthContext.Provider>
    );
}

const AuthConsumer = AuthContext.Consumer


export { AuthContext, AuthProvider, AuthConsumer }