export const skuKey = "sku";
const CreateVariantField = (schema, item, required = false) => {
  let options = [];
  let optionNames = [];
  let defaulValue = "";

  // if the item has more than one variant field
  if (item.variant_fields.length > 1) {
    optionNames = Object.keys(item.variants).map(var_key => {
      const v = item.variants[var_key];
      return Object.keys(v)
        .map(vk => { 
          return vk + ": " + v[vk];
        })
        .join(", ");
    });
    options = Object.keys(item.variants);
  }
  if (item.variant_fields.length == 1) {
    optionNames = Object.keys(item.variants).map(var_key => {
      const v = item.variants[var_key];
      return Object.keys(v)
        .map(vk => {
          return v[vk];
        })
        .join(", ");
    });
    options = Object.keys(item.variants);
  }
  if (item.variant_fields.length < 1) {
    optionNames = ["ONE OPTION"];
    options = Object.keys(item.variants);
  }
  defaulValue = item.variant_default || options[0];

  // build the new field
  const newField = {
    title: "Product Option",
    type: "string",
    enumNames: optionNames,
    enum: options,
    default: defaulValue
  };
  // console.log("NEW FIELD", item.variant_fields.length, item.name, newField);

  schema.properties = { [skuKey]: newField, ...schema.properties };

  if (schema.required == undefined) schema.required = [];

  if (schema.required.indexOf(skuKey) == -1) schema.required.unshift(skuKey);

  return schema;
};

export default CreateVariantField;
