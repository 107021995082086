
import * as PostMessageTypes from "@matter/code-lib/postmessage/types";
// import { OrderContext } from "../context/OrderContext";

const PostMessageListener = (orderContext) => {


  /**
   * handles incoming messages from post message
   * @param {object} message incoming packet
   */
  const handle = message => {
    console.log("OPERATOR UI PostMessageListener", message.data.action, message.data.body)
    const { action, body } = message.data;
    switch (action) {
      case PostMessageTypes.WIDGET_DATA_RESPONSE:
        // console.log("PostMessage -----------",PostMessageTypes.WIDGET_DATA_RESPONSE, body.customer);
        // const orderContext = useContext(OrderContext);
        if (body && body.customer) {
          orderContext.setCustomer(body.customer);
        }

        break;

      default:
        return null;
    }
  };

  return {
    handle
  }
};

export default PostMessageListener;
