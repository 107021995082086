/**
 * OrderCreate Command
 * @desc Creates an order object from a sendable 
 * @author: Dave Ashenden 
 */
const OrderCreate = (order, apps = {}) => {
    //  transpose the items into the api format
    const { customer, sendable, lineItems, notes } = order
    const items = Object.keys(lineItems).map(
        (key) => {
            const data = JSON.parse(JSON.stringify(lineItems[key]));
            const sku = data.sku
            delete data.sku

            // return { configuration: data, sku: sku };
            return { id: key, configuration: data, sku: sku };
        }
    )


    const base = {
        "sendable": {
            "sendable_id": sendable.id,
            // "message": "",
            "items": items
        },
        "customer": customer,
        "notes": notes
    }

    const result = { ...base, ...apps }

    return result
}

export default OrderCreate


