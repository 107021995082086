import React, { useEffect, useState } from "react";
import styles from "./PersonalisationFormItemComponent.module.sass";
import cx from "classnames";
import PropTypes from "prop-types";
import Form from "react-jsonschema-form";
import CreateVariantField from "../../commands/JsonSchemaTools/CreateVariantField";
import { Spin } from "antd";

/*
 * PersonalisationFormItem Component
 */
const PersonalisationFormItem = props => {
  // const [loading, setLoading] = useState(true);
  const { schemas, formConfiguration, item } = props;

  const [schema, setSchema] = useState();
  // const [variants, setVariants] = useState([]);
  const [data, setData] = useState();
  // // set the loading to false when a schema is recieved
  useEffect(() => {
    // if (schemas["id"]) {
    //   setLoading(false);
    // }
    let newSchema = {};
    let newData = { ...props.data };

    // console.log("--------------------------------------");
    // console.log(item.name, "LOAD");
    // console.log("--------------------------------------");
    if (schemas.schema && item) {
      // console.log(item.name, "START");
      newSchema = schemas.schema;
      // newData = { ...propsdata };
      // wipe out the fields that are for a variant
      item.variant_fields.map(f => {
        delete newSchema.properties[f];
        delete newData[f];
      });

      // remove any requires from the schema for variant options
      if (newSchema["required"] != undefined)
        newSchema.required = newSchema.required.filter(
          value => newSchema.required.indexOf(value) == -1
        );

      // set up a new select field call variant,
      newSchema = CreateVariantField(newSchema, item, true);
      // console.log(item.name, "CREATEDFIELD");

      // set in state
      setData(newData);
      setSchema(newSchema);
      // console.log("--------------------------------------");
      // console.log("form Item Renderer", newSchema, newData);
      // console.log("--------------------------------------");
    }
  }, [schemas, item, props.data]);

  //   hndlers for props
  const onChange = data => {
    // console.log("ITEM changed", item, data);
    props.onChange(item.id, data);
  };
  const onSubmit = ({ formData }, e) => {
    console.log("submitted", formData, e);

    // props.onChange(id,data)
  };
  const onError = data => {
    props.onError(item.id, data);
    console.log("error");
  };

  //   blacnking field

  const CustomTitleField = ({ title, required }) => {
    // const legend = required ? title + '*' : title;
    return null; //<div id="custom">YEAH {legend}</div>;
  };

  const fields = {
    TitleField: CustomTitleField,
    LabelField: CustomTitleField
  };
  const uiSchema = {
    message: {
      "ui:widget": "matter:messageWidget",
      "ui:options": formConfiguration
    }
  };

  const uialt = uiSchema; //schemas.ui_schema || {};

  return (
    <div className={cx("d-flex-column", styles.container)}>
      {schema && data && Object.keys(schema.properties).length > 0 && (
        <Spin
          spinning={
            !schema || !data || Object.keys(schema.properties).length < 1
          }
        >
          <Form
            schema={schema}
            uiSchema={uialt}
            fields={fields}
            formData={data}
            widgets={props.widgets}
            onChange={onChange}
            onSubmit={onSubmit}
            onError={onError}
            liveValidate
          >
            <div>{/* <button type="submit">Update</button> */}</div>
          </Form>
        </Spin>
      )}
    </div>
  );
};

PersonalisationFormItem.propTypes = {
  item: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  widgets: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  onSubmit: PropTypes.func
};
PersonalisationFormItem.defaultProps = {
  item: {},
  data: {},
  widgets: {}
};

export default PersonalisationFormItem;
