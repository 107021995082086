
import {useContext,useEffect} from 'react'
import { OrderContext } from '../../context/OrderContext';
import PostMessageListener from '../../services/PostMessageListener';

import {sendPostMessageToParent} from '@matter/code-lib/postmessage/utils'
import * as PostMessageTypes from '@matter/code-lib/postmessage/types'


/*
 * PostMessageComponent Component 
 */
const PostMessageComponent = (props) => {

    const orderContext = useContext(OrderContext)
    
    const myListener = new PostMessageListener(orderContext)

    useEffect(() => {
        console.log("PostMessageComponent")
        // tell the container that we have loaded
        window.removeEventListener('message', myListener.handle)
        window.addEventListener('message', myListener.handle)
        // Register the listener service
        sendPostMessageToParent(PostMessageTypes.WIDGET_BOOTSTRAPPED, {})
    }, [])

    return null
}

export default PostMessageComponent


