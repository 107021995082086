import React, { useState, useEffect, useContext } from 'react'
import cx from 'classnames'
import styles from './SendableSearch.module.sass'
import { Input, Icon, Tag } from "antd";
import { AppContext } from '../../context/AppContext'

// import SendableFilter from '../../commands/SendableFilter'

const SendableSearch = (props) => {

  const [tags, setTags] = useState([])
  // const [term, setTerm] = useState('')
  const { sendables, sendableFilter, setSendableFilter } = useContext(AppContext)
  // const {filteredCamapigns, setFilteredCamapigns} = useState([])

  useEffect(

    () => {
      if (!sendables) {
        return
      }
      // console.log("CAMPAIGN SEARCH", sendables)
      const tags = Object.keys(sendables).map(

        (key, idx) => {
          const sendable = sendables[key]
          return sendable.tag_list.map(
            (tag, tidx) => {
              return tag
            }
          )// tag mapp
        }
      ).flat()

      const utags = tags.filter(function (e) {
        if (tags[e] === undefined) {
          tags[e] = true;
          return true;
        }
        return false;
      });
      // });

      setTags(utags)
    }, [sendables]
  )


  const setSearchTerm = (term) => {
    //  console.log(term)
    //  console.log(SendableFilter(sendables, term)[1])
    setSendableFilter(term)
    //  setFilteredCamapigns(SendableFilter(sendables, term)[1])
  }

  return (
    <div className={cx("container text-center", styles.container)}>
      <div className="row pb-3">
        <Input
          value={sendableFilter}
          onChange={e => setSearchTerm(e.target.value)}
          placeholder="I need a.."
          addonBefore={
            <Icon
              type="search"
              size="large"
              style={{ color: "rgba(0,0,0,.75)", fontSize: "1.5em" }}
            />
          }
          className="mx-3"
        />
      </div>

      {tags.map((tag, index) => {
        return (
          <React.Fragment key={"filter-badge" + index}>
            <Tag
              className="m-2"
              color={tag === sendableFilter ? "#bbb" : null}
              size="small"
              key={tag}
              onClick={e => setSendableFilter(tag)}
            >
              {tag}
            </Tag>

          </React.Fragment>
        );
      })}
    </div>
  );

}


// SendableSearch.propTypes = {
//   sendables: PropTypes.array
// }
// SendableSearch.defaultProps = {
//   sendables: []
// };

export default SendableSearch