import React, { useState, useContext } from 'react';
import axios from 'axios'
import { normalize, schema } from 'normalizr'
import * as Sentry from "@sentry/browser";

import { saveLocal } from '../commands/LocalStorage'
import { AuthContext } from './AuthContext';
const sendablesUrl = process.env.REACT_APP_API_BASE + 'sendables'
const postOrderUrl = process.env.REACT_APP_API_BASE + 'orders'

const AppContext = React.createContext();


const AppProvider = (props) => {

  // const steps = [
  //   "select",
  //   "customer",
  //   "personalise",
  //   "review",
  //   "shipped"
  // ]

  // const [activeStepIdx, setActiveStepIdx] = useState(0)
  // const activeStep = () => steps[activeStepIdx]
  // const setActiveStep = (step) => setActiveStepIdx(steps.indexOf(step))
  // const moveSteps = (amount) => {
  //   const nextStep  = activeStepIdx + amount
  //   if(nextStep >  steps.length-1)
  //     return steps.length-1
  //   if(nextStep < 0)
  //     return 0

  //   return nextStep
  // } 


  // useEffect(() => {
  //   console.log("HI FROM APPCONTEXT", activeSendable);
  //   // TODO: 
  // }, [activeSendable]);

  // const orderContext = useContext(OrderContext)
  // const [isDigital, setIsDigital] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(null)


  // const [activeSendable] = useState(null)

  // Sendables
  const [sendables, setSendables] = useState({})
  const [activeSendableId, setActiveSendableIdState] = useState(null)
  const [activeSendable, setActiveSendable] = useState(null)
  const [sendableFilter, setSendableFilter] = useState('')

  const authContext = useContext(AuthContext)

  // const activeSendable = activeSendableId ? sendables[activeSendableId] : null
  const setActiveSendableId = (id) => {
    console.log("SETTING ACTIVE TOUCHPOINT TO", sendables[id])
    setActiveSendableIdState(id)
    setActiveSendable(sendables[id])
  }

  


  // const getFilteredSendables(term) {
  //   setSendableSearchTermState(term)

  // }

  const getSendables = () => {
    // console.log("GET CAMPAIGNS ")
    setIsLoading(true)
    if (Object.keys(sendables).length === 0) {
      console.log("AUTH", authContext.token)
      axios.get(sendablesUrl, { headers: { "Authorization": "Bearer " +authContext.token } }).then(
        response => {
          // console.log("GET CAMPAIGNS RESPONSE")
          if (response.status === 200) {
            const sendableSchema = new schema.Array(new schema.Entity('sendables'))
            console.log("RESPONSE", response.data)
            if (response.data.length > 0) {
              const normalizedData = normalize(response.data.filter(
                tp => {
                  // console.log(tp.availability, "av");
                  // return tp.availability.available || true
                  return tp.availability.available || true
                }
              ), sendableSchema)
              const sendables = normalizedData.entities.sendables
              // console.log(JSON.stringify(sendables))
              setSendables(sendables)
            } else {
              setSendables({})

            }
            // saveLocal('sendables', sendables)

          } else {
            setIsError("Oops,we couldn't retrieve your sendables,*try again* , and if the problem persists, please *contact us*")
            setSendables({})
          }
          setIsLoading(false)
        }
      ).catch(

        (e) => {
          setIsLoading(false)
        }
      )
    } else {
      setIsLoading(false)

    }

  }


  const postOrder = (order, callbackSuccess = () => null, callbackFailure = () => null) => {
    // console.log("postOrder", {"Authorization": localStorage.getItem('token')})
    setIsLoading(true)

    // console.log("AUTH", localStorage.getItem('token'))
    axios.post(
      postOrderUrl,
      order,
      { headers: { "Authorization": "Bearer " +authContext.token } }).then(
        response => {
          // console.log("CREATE ORDER RESPONSE", response)
          // // if( response.status === 200){
          //   const sendableSchema = new schema.Array(new schema.Entity('sendables'))
          //   const normalizedData = normalize(response.data, sendableSchema)
          //   const sendables = normalizedData.entities.sendables
          //   setSendables(sendables)
          //   saveLocal('sendables', sendables)

          // }else{
          //   setIsError("Oops,we couldn't retrieve your sendables,*try again* , and if the problem persists, please *contact us*")
          //   setSendables({})
          // }
          callbackSuccess(response)
          setIsLoading(false)
        },
        error => {
          Sentry.withScope(scope => {
            scope.setExtras({ error: error, order: order });
            // const eventId = Sentry.captureException(error);
          });
          callbackFailure(error)
          setIsLoading(false)
        }

      )


  }

  return (
    <AppContext.Provider value={{
      isError,
      isLoading,
      setIsLoading,
      // steps,
      // moveSteps,
      // activeStep,
      // setActiveStep,
      postOrder,

      sendables,
      getSendables,
      activeSendable,
      activeSendableId,
      setActiveSendableId,
      sendableFilter,
      setSendableFilter


    }}>
      {props.children}
    </AppContext.Provider>
  )
}

const AppConsumer = AppContext.Consumer


export { AppContext, AppProvider, AppConsumer }